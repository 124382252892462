import React from "react";
import "../../css/topper.scss";

function Topper() {
  let textValue = "";
  if (window.location.pathname.includes("translate")) {
    textValue = "번역 기능";
  }
  if (window.location.pathname.includes("colorize")) {
    textValue = "자동 채색";
  }
  if (window.location.pathname.includes("divide")) {
    textValue = "컷 분리";
  }
  if (window.location.pathname.includes("resolution")) {
    textValue = "이미지 해상도 개선";
  }
  if (window.location.pathname.includes("denoise")) {
    textValue = "이미지 선명도 개선";
  }
  if (window.location.pathname.includes("transfer")) {
    textValue = "컬러 변환";
  }

  const backLink = () => {
    window.location.href = window.location.origin;
  };

  return (
    <div id="topperDiv">
      <div id="back" onClick={() => backLink()}>
        <i className="fa-solid fa-arrow-left-long"></i> Back
      </div>
      <div id="topperText">{textValue}</div>
      <img
        className="_white"
        src="/ideaconcert/white.webp"
        alt="logo"
        srcSet="/ideaconcert/white@2x.webp 2x,/ideaconcert/white@3x.webp 3x"
      />
    </div>
  );
}

export default Topper;
