import React from "react";
import "../css/buttons.scss";

function Buttons() {
  function changeLink(word) {
    window.location.href = window.location.href + word;
  }
  return (
    <div id="basicContainer">
      <div id="top">
        <div>데모 페이지 기능</div>
        <img
          className="_white"
          src="/ideaconcert/white.webp"
          srcSet="/ideaconcert/white@2x.webp 2x,/ideaconcert/white@3x.webp 3x"
          alt="icon"
        />
      </div>
      <div id="buttonContainer">
        <button
          className="buttons"
          id="colorize"
          onClick={() => changeLink("colorize")}
        >
          <img
            className="icon"
            src="\button\editor\free-icon-text-editor-4927335.png"
            srcSet="\button\editor\free-icon-text-editor-4927335@2x.png 2x,
          \button\editor\free-icon-text-editor-4927335@3x.png 3x"
            alt="icon"
          />
          <span>자동 채색</span>
          <div id="line"></div>

          <span id="-AI">
            지정한 무드에 따라 자동으로 채색을 도와주는 AI기술
          </span>
        </button>
        <button
          className="buttons"
          id="resolution"
          onClick={() => changeLink("resolution")}
        >
          <img
            className="icon"
            src="\button\shines\free-icon-shines-764690.png"
            srcSet="\button\shines\free-icon-shines-764690@2x.png 2x,
            \button\shines\free-icon-shines-764690@3x.png 3x"
            alt="icon"
          />
          <span>만화 이미지 해상도 개선</span>
          <div id="line"></div>

          <span id="-AI">
            페이지 손상 및 해상도 저하, 노이즈 등을 제거하여 이미지의 품질을
            높이는 AI기술
          </span>
        </button>
        <button
          className="buttons"
          id="divide"
          onClick={() => changeLink("divide")}
        >
          <img
            className="icon"
            src="\button\bricks\free-icon-text-editor-4927335.png"
            srcSet="\button\bricks\free-icon-text-editor-4927335@2x.png 2x,
          \button\bricks\free-icon-text-editor-4927335@3x.png 3x"
            alt="icon"
          />
          <span>만화 컷 분리</span>
          <div id="line"></div>

          <span id="-AI">
            하나의 이미지로 된 만화책의 컷을 자동으로 분리시켜주는 자동 기술
          </span>
        </button>
        <button
          className="buttons"
          id="transfer"
          onClick={() => changeLink("transfer")}
        >
          <img
            className="icon"
            src="\button\painter\icon.png"
            srcSet="\button\painter\icon@2x.png 2x,
          \button\painter\icon@3x.png 3x"
            alt="icon"
          />
          <span>컬러 변환</span>
          <div id="line"></div>

          <span id="-AI">지정한 영역의 컬러를 변환시켜주는 AI기술</span>
        </button>
        <button
          className="buttons"
          id="denoise"
          onClick={() => changeLink("denoise")}
        >
          <img
            className="icon"
            src="\button\eyes\icon.png"
            srcSet="\button\eyes\icon@2x.png 2x,
          \button\eyes\icon@3x.png 3x"
            alt="icon"
          />
          <span>웹툰 이미지 선명도 개선</span>
          <div id="line"></div>

          <span id="-AI">
            해상도 저하 및 노이즈를 개선하여 품질 높은 이미지를 만들어주는
            AI기술
          </span>
        </button>
        <button
          className="buttons"
          id="translate"
          onClick={() => changeLink("translate")}
        >
          <img
            className="icon"
            src="\button\text\icon.png"
            srcSet="\button\text\icon@2x.png 2x,
          \button\text\icon@3x.png 3x"
            alt="icon"
          />
          <span>자동 번역</span>
          <div id="line"></div>

          <span id="-AI">
            웹툰의 말풍선을 인식하여 자동으로 번역을 도와주는 AI기술
          </span>
        </button>
      </div>
    </div>
  );
}

export default Buttons;
