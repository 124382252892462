import React, { useState } from "react";
import Topper from "./parts/topper";
import Translateloading from "./parts/translateLoading";
import "../css/translate.scss";
import axios from "axios";

function Translate() {
  const [file, setFile] = useState(null);
  const [loading, setload] = useState(false);

  const importing = (e) => {
    if (e.target.files.length !== 0) {
      setload(true);
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let sizeCheck = new Image();
        sizeCheck.src = reader.result;
        sizeCheck.onload = () => {
          let h = sizeCheck.height;

          if (20000 < h) {
            alert("파일이 너무 큽니다.\n작은 파일을 이용해주세요.");
            setFile(null);
            window.location.reload();
          } else {
            setFile(reader.result);
          }
        };
      };
      setTimeout(() => {
        setload(false);
      }, 500);
    }

    document.getElementById("original").src = "";
    document.getElementById("empty").src = "";
    while (
      document.getElementById("right").children.length &&
      document.getElementsByClassName("rightContainer").length
    ) {
      document
        .getElementById("right")
        .removeChild(document.getElementsByClassName("rightContainer")[0]);
    }

    while (
      1 < document.getElementById("blank").children.length &&
      document.getElementsByClassName("textBox").length
    ) {
      document
        .getElementById("blank")
        .removeChild(document.getElementsByClassName("textBox")[0]);
    }
  };

  const toontra = () => {
    let fd = new FormData();
    let langTarget = document.getElementById("langTarget").value;
    fd.append("img", document.getElementById("fileInput").files[0]);
    fd.append("langTarget", langTarget);
    setload(true);
    axios
      .post(process.env.REACT_APP_SERVER + "/demo/toontra/", fd, {
        header: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        let data = res.data;
        let blankIMG = document.getElementById("empty");
        blankIMG.src =
          process.env.REACT_APP_SERVER +
          "/media/temp/" +
          data["uuid"] +
          "/" +
          document
            .getElementById("fileInput")
            .files[0].name.replace(".", "_b.");
        blankIMG.onload = () => {
          let right = document.getElementById("right");
          if (document.getElementById("rightNull")) {
            right.removeChild(document.getElementById("rightNull"));
          }
          data["bubble"].sort((a, b) => a.top - b.top);
          for (let n = 0; n < data["bubble"].length; n++) {
            let item = data["bubble"][n];
            let containerDiv = document.createElement("div");
            containerDiv.className = "rightContainer";
            let originalDiv = document.createElement("div");
            originalDiv.className = "originalDiv";
            originalDiv.textContent = data["bubble"][n]["original_text"];

            //google
            let googleDiv = document.createElement("div");
            googleDiv.id = "google";
            let googleLabel = document.createElement("span");
            googleLabel.id = "googleLabel";
            googleLabel.textContent = "Google : ";
            let googleText = document.createElement("span");
            googleText.textContent = data["bubble"][n]["google"];
            googleDiv.appendChild(googleLabel);
            googleDiv.appendChild(googleText);

            //papago
            let papagoDiv = document.createElement("div");
            papagoDiv.id = "papago";
            let papagoLabel = document.createElement("span");
            papagoLabel.id = "papagoLabel";
            papagoLabel.textContent = "Papago : ";
            let papagoText = document.createElement("span");
            papagoText.textContent = data["bubble"][n]["papago"];
            papagoDiv.appendChild(papagoLabel);
            papagoDiv.appendChild(papagoText);

            containerDiv.append(originalDiv);
            containerDiv.append(googleDiv);
            containerDiv.append(papagoDiv);

            let originHeight = data["height"];
            let originWidth = data["width"];
            let textBox = document.createElement("div");
            textBox.className = "textBox";
            // textBox.style.border = "2px black solid";
            textBox.style.position = "absolute";
            textBox.style.display = "flex";
            textBox.style.alignItems = "center";
            textBox.style.justifyContent = "center";
            textBox.style.top =
              Math.floor(
                (item["top"] / originHeight) *
                  document.getElementById("original").clientHeight
              ) + "px";
            textBox.style.left =
              Math.floor(
                (item["left"] / originWidth) *
                  document.getElementById("original").clientWidth
              ) + "px";
            if (document)
              textBox.style.height =
                Math.floor((item["height"] / originHeight) * 10000) / 100 + "%";
            textBox.style.width =
              Math.floor((item["width"] / originWidth) * 10000) / 100 + "%";
            textBox.textContent = item["google"];
            document.getElementById("blank").append(textBox);

            let inputBox = document.createElement("textarea");
            inputBox.className = "inputBox";
            inputBox.value = data["bubble"][n]["google"];
            inputBox.onkeyup = (e) => {
              setTimeout(() => {
                textBox.innerHTML = e.target.value.replace("\n", "<br/>");
              }, 1);
            };
            containerDiv.append(inputBox);

            //google , papago button
            let buttonDiv = document.createElement("div");
            buttonDiv.id = "buttonDiv";
            let gooButton = document.createElement("button");
            gooButton.id = "gooButton";
            gooButton.textContent = "Google";
            gooButton.onclick = () => {
              textBox.textContent = data["bubble"][n]["google"];
              inputBox.value = data["bubble"][n]["google"];
            };
            let papButton = document.createElement("button");
            papButton.id = "papButton";
            papButton.textContent = "Papago";
            papButton.onclick = () => {
              textBox.textContent = data["bubble"][n]["papago"];
              inputBox.value = data["bubble"][n]["papago"];
            };
            buttonDiv.appendChild(gooButton);
            buttonDiv.appendChild(papButton);
            containerDiv.appendChild(buttonDiv);

            right.append(containerDiv);
            setload(false);
          }
        };

        //japanese & chinese writing system rules
        // if (document.getElementById("langTarget").value === "Japanese") {
        //   document.getElementById("blank").style.writingMode = "vertical-rl";
        //   document.getElementById("blank").style.textOrientation = "upright";
        // } else {
        //   document.getElementById("blank").style.writingMode = "horizontal-tb";
        //   document.getElementById("blank").style.textOrientation = "";
        // }
      })
      .catch((err) => {
        alert(
          "서버와의 통신 중 연결이 종료되었습니다. 처음부터 다시 시도해주세요."
        );
        window.location.reload();
      });
  };

  const langSelectChange = () => {
    while (document.getElementsByClassName("rightContainer").length) {
      document.getElementsByClassName("rightContainer")[0].remove();
    }

    while (document.getElementsByClassName("textBox").length) {
      document.getElementsByClassName("textBox")[0].remove();
    }
  };

  const changeBot = () => {
    if (document.getElementById("rightNull")) {
      alert("번역 진행 후 시도해주세요");
    } else {
      if (document.getElementById("right").style.display === "flex") {
        document.getElementById("right").style.display = "none";
        document.getElementById("left").style.display = "flex";
      } else {
        document.getElementById("left").style.display = "none";
        document.getElementById("right").style.display = "flex";
      }
    }
  };

  return (
    <div id="translateContainer">
      {loading ? <Translateloading /> : <></>}
      <Topper />
      <div id="translate">
        <div id="inputButton">
          <input
            id="fileInput"
            type="file"
            accept=".png,.jpg,.jpeg,.pdf,.psd"
            onChange={(e) => {
              importing(e);
            }}
          />
          <select id="langTarget" onChange={() => langSelectChange()}>
            <option value="English">English</option>
            <option value="Korean">한국어</option>
            <option value="Japanese">日本語</option>
            <option value="Chinese">中国人</option>
          </select>
          <button id="workButton" onClick={() => toontra()}>
            Start
          </button>
          <button
            id="resetButton"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reset
          </button>
          <button id="updateButton" onClick={() => changeBot()}>
            번역 수정
          </button>
        </div>
        <div id="transBot">
          <div id="left">
            <div className="imgContainer">
              <img id="original" src={file} alt="파일을 입력해주세요" />
            </div>
            <i className="fa-solid fa-arrow-right-long" id="arrowRight"></i>
            <div className="imgContainer" id="blank">
              <img id="empty" src="" alt="작업을 시작해주세요" />
            </div>
          </div>
          <div
            id="right"
            onScroll={(e) => {
              console.log(e.target);
            }}
          >
            <div id="rightNull">작업을 시작해주세요</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Translate;
