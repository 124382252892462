import React, { useState } from "react";
import Topper from "./parts/topper";
import ColorizeLoading from "./parts/colorizeLoading";
import axios from "axios";

function Transfer() {
  const [file, setFile] = useState(null);
  const [worked, setWork] = useState(null);
  const [loading, setload] = useState(false);

  const importing = (e) => {
    if (e.target.length !== 0) {
      setload(true);
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let sizeCheck = new Image();
        sizeCheck.src = reader.result;
        sizeCheck.onload = () => {
          let h = sizeCheck.height;

          if (10000 < h) {
            alert("파일이 너무 큽니다.\n작은 파일을 이용해주세요.");
            setFile(null);
            window.location.reload();
          } else {
            setFile(reader.result);
          }
        };
      };
      setTimeout(() => {
        setload(false);
      }, 500);
    }
  };

  const clickButton = () => {
    let file = document.getElementById("fileInput").files[0];

    let fd = new FormData();
    fd.append("img", file);

    setload(true);

    axios
      .post(process.env.REACT_APP_SERVER + "/test/colorize/", fd, {
        header: { "Content-Type:": "form-data" },
      })
      .then((res) => {
        // document.getElementById("worked").src =
        //   process.env.REACT_APP_SERVER + "/media/temp/" + res.data.location;
        setWork(
          process.env.REACT_APP_SERVER + "/media/temp/" + res.data.location
        );
        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="colorizeContainer">
      {loading ? <ColorizeLoading /> : <></>}
      <Topper />
      <div id="inputButton">
        <input
          id="fileInput"
          type="file"
          accept=".png,.jpg,.jpeg,.pdf"
          onChange={(e) => {
            importing(e);
          }}
        />
        <button
          id="workButton"
          onClick={() => {
            clickButton();
          }}
        >
          Start
        </button>
        <button
          id="resetButton"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reset
        </button>
      </div>
      <div id="imgContainer">
        <div id="imgDiv">
          <img
            id="original"
            src={file}
            width="100%"
            alt="이미지를 입력해주세요"
          />
        </div>
        <i className="fa-solid fa-arrow-right-long" id="arrowRight"></i>
        <div id="imgDiv">
          <img id="worked" src={worked} alt="작업을 진행해주세요" />
        </div>
      </div>
    </div>
  );
}

export default Transfer;
