import "./App.scss";
import { useState, useEffect } from "react";
import Login from "./component/login";
import ButtonPage from "./component/buttonPage";

function App() {
  const [loginState, setlogin] = useState(false);
  useEffect(() => {
    if (window.sessionStorage.getItem("login") === "true") {
      setlogin(true);
    } else {
      setlogin(false);
    }
  }, [window.sessionStorage.getItem("login")]);

  return (
    <div className="App">
      <img
        className="back"
        src="/login/back.png"
        srcSet="/login/back@2x.png 2x,/login/back@3x.png 3x"
        alt="BG-IMG"
      />
      {loginState ? <ButtonPage /> : <Login />}
    </div>
  );
}

export default App;
