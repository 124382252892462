import React, { useState } from "react";
import Topper from "./parts/topper";
import ResolutionLoading from "./parts/resolutionLoading";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import "../css/denoise.scss";

function Denoise() {
  const [file, setFile] = useState(null);
  const [worked, setWork] = useState(null);
  const [pdfFile, setPDF] = useState(null);
  const [loading, setload] = useState(false);
  const [fileType, setFileType] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const importing = (e) => {
    if (e.target.length !== 0) {
      setload(true);
      let file = e.target.files[0];
      if (!file.name.includes("pdf")) {
        setFileType(false);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let sizeCheck = new Image();
          sizeCheck.src = reader.result;
          sizeCheck.onload = () => {
            let h = sizeCheck.naturalHeight;
            let w = sizeCheck.naturalWidth;

            if (10000 < h || 1500 < w) {
              alert("파일이 너무 큽니다.\n작은 파일을 이용해주세요.");
              setFile(null);
              window.location.reload();
            } else {
              setFile(reader.result);
            }
          };
        };
      } else {
        setFileType(true);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        let url = URL.createObjectURL(file);
        setFile(url);
      }
      setTimeout(() => {
        setload(false);
      }, 500);
    }
  };

  const resolutionAPI = () => {
    let file = document.getElementById("fileInput").files[0];

    let fd = new FormData();

    fd.append("img", file);
    setload(true);
    // console.log("true");

    axios
      .post(process.env.REACT_APP_SERVER + "/resolution/", fd, {
        header: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (!file.name.includes("pdf")) {
          setWork(
            process.env.REACT_APP_SERVER +
              "/media/resolution" +
              res.data.location.replace(".", "_SR.")
          );
        } else {
          setPDF(
            process.env.REACT_APP_SERVER +
              "/media/resolution" +
              res.data.location.replace(".", "_SR.")
          );
        }
        setTimeout(() => {
          setload(false);
          console.log("false");
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function scrollMatcher(e) {
    if (e.target.id === "workedDiv") {
      document.getElementById("imgDiv").scrollTop = e.target.scrollTop;
    } else {
      if (document.getElementById("workedDiv")) {
        document.getElementById("workedDiv").scrollTop = e.target.scrollTop;
      }
    }
  }

  function IMGviewer() {
    return (
      <div id="imgContainer">
        <div id="imgDiv" onScroll={(e) => scrollMatcher(e)}>
          <img
            id="original"
            src={file}
            width="100%"
            alt="이미지를 입력해주세요"
          />
        </div>
        <i className="fa-solid fa-arrow-right-long" id="arrowRight"></i>
        <div id="workedDiv" onScroll={(e) => scrollMatcher(e)}>
          <img id="worked" src={worked} alt="작업을 진행해주세요" />
        </div>
      </div>
    );
  }

  function PDFviewer() {
    return (
      <div id="imgContainer">
        <div id="imgDiv">
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <p>
            <span
              onClick={() =>
                pageNumber > 1 ? setPageNumber(pageNumber - 1) : null
              }
            >
              &lt; 이전 페이지
            </span>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <span
              onClick={() =>
                pageNumber < numPages ? setPageNumber(pageNumber + 1) : null
              }
            >
              다음 페이지 &gt;
            </span>
          </p>
        </div>
        <i className="fa-solid fa-arrow-right-long" id="arrowRight"></i>
        <div id="workedDiv">
          <Document
            id="imgDiv"
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={0.25}
            />
          </Document>
        </div>
      </div>
    );
  }

  return (
    <div id="denoiseContainer">
      {loading ? <ResolutionLoading /> : <></>}
      <Topper />
      <div id="inputButton">
        <input
          id="fileInput"
          type="file"
          accept=".png,.jpg,.jpeg,.pdf,.psd"
          onChange={(e) => {
            importing(e);
          }}
        />
        <button
          id="workButton"
          onClick={() => {
            resolutionAPI();
          }}
        >
          Start
        </button>
        <button
          id="resetButton"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reset
        </button>
      </div>
      {fileType ? <PDFviewer /> : <IMGviewer />}
    </div>
  );
}

export default Denoise;
