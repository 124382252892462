import React from "react";
import "../../css/parts/colorizeLoading.scss";

function ColorizeLoading() {
  return (
    <div id="colorizeLoadingDiv">
      <div id="bigCircle"></div>
      <div id="all">
        <div id="box">
          <div id="circle"></div>
        </div>
        <div id="box" className="box2">
          <div id="circle" className="circle2"></div>
        </div>
        <div id="box" className="box3">
          <div id="circle" className="circle3"></div>
        </div>
        <div id="box" className="box4">
          <div id="circle" className="circle4"></div>
        </div>
        <div id="box" className="box5">
          <div id="circle" className="circle5"></div>
        </div>
      </div>
      <div id="smallCircle">Loading...</div>
    </div>
  );
}

export default ColorizeLoading;
