import React from "react";
import "../css/login.scss";

function Login() {
  const clickButton = () => {
    if (
      document.getElementById("email").value ===
        process.env.REACT_APP_TESTING_ID &&
      document.getElementById("pw").value === process.env.REACT_APP_TESTING_PW
    ) {
      window.sessionStorage.setItem("login", true);
    } else {
      window.sessionStorage.setItem("login", false);
    }
    window.location.reload();
  };
  return (
    <div id="login">
      <span className="IDEACONCERT-Technology-Demo-Page">
        IDEACONCERT Technology Demo Page
      </span>
      <div id="loginContainer">
        <input className="loginInput" id="email" placeholder="Email" />
        <input
          className="loginInput"
          type="password"
          id="pw"
          placeholder="PW"
        />
      </div>
      <button id="loginButton" onClick={(e) => clickButton(e)}>
        데모서비스 체험하기
      </button>
    </div>
  );
}

export default Login;
