import React, { useState } from "react";
import Topper from "./parts/topper";
import DivideLoading from "./parts/divideLoading";
import "../css/divide.scss";
import axios from "axios";

function Devide() {
  const [file, setFile] = useState(null);

  const [loading, setload] = useState(false);

  const importing = (e) => {
    if (e.target.length !== 0) {
      setload(true);
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let sizeCheck = new Image();
        sizeCheck.src = reader.result;
        sizeCheck.onload = () => {
          let h = sizeCheck.height;

          if (10000 < h) {
            alert("파일이 너무 큽니다.\n작은 파일을 이용해주세요.");
            setFile(null);
            window.location.reload();
          } else {
            setFile(reader.result);
          }
        };
      };
      setTimeout(() => {
        setload(false);
      }, 500);
    }
  };

  const clickButton = () => {
    setload(true);
    let file = document.getElementById("fileInput").files[0];

    let fd = new FormData();
    fd.append("files", file);

    axios
      .post("https://api2.toonivie.com/save-image/", fd, {
        header: { "Content-Type:": "form-data" },
      })
      .then((res) => {
        let listing = res.data["cutInfo"][0]["imgList"];
        listing = listing.sort((a, b) => {
          return a.originLocation.y - b.originLocation.y;
        });

        let largeWidth = 0;

        for (let n = 0; n < listing.length; n++) {
          if (largeWidth < listing[n].width) {
            largeWidth = listing[n].width;
          }
        }

        if (document.getElementById("worked")) {
          document.getElementById("worked").remove();
        }
        while (document.getElementsByClassName("cutted").length) {
          document.getElementsByClassName("cutted")[0].remove();
        }
        res.data["cutInfo"][0]["imgList"].map((item, index) => {
          let img = new Image();
          img.className = "cutted";
          img.src = "https://api3.toonivie.com/" + item["url"];
          img.style.width = Math.floor((item.width / largeWidth) * 100) + "%";
          document.getElementById("workedDiv").append(img);

          if (index !== 0) {
            img.style.marginTop =
              Math.floor((Math.random() + 0.5) * 10) * 10 + "px";
          }
        });

        setload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="divideContainer">
      {loading ? <DivideLoading /> : <></>}
      <Topper />
      <div id="inputButton">
        <input
          id="fileInput"
          type="file"
          accept=".png,.jpg,.jpeg,.pdf"
          onChange={(e) => {
            importing(e);
          }}
        />
        <button
          id="workButton"
          onClick={() => {
            clickButton();
          }}
        >
          Start
        </button>
        <button
          id="resetButton"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reset
        </button>
      </div>
      <div id="imgContainer">
        <div id="imgDiv">
          <div id="float">업로드한 이미지</div>
          <img
            id="original"
            src={file}
            width="100%"
            alt="이미지를 입력해주세요"
          />
        </div>
        <i className="fa-solid fa-arrow-right-long" id="arrowRight"></i>
        <div id="workedDiv">
          <div id="float">기술 적용 이미지</div>
          <img id="worked" src="" alt="작업을 진행해주세요" />
        </div>
      </div>
    </div>
  );
}

export default Devide;
