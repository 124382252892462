import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Colorize from "./colorize";
import Devide from "./divide";
import Resolution from "./resolution";
import Transfer from "./transfer";
import Translate from "./translate";
import Buttons from "./buttons";
import Denoise from "./denoise";

function ButtonPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Buttons />} />
        <Route path="/colorize" element={<Colorize />} />
        <Route path="/divide" element={<Devide />} />
        <Route path="/resolution" element={<Resolution />} />
        <Route path="/denoise" element={<Denoise />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/translate" element={<Translate />} />
      </Routes>
    </BrowserRouter>
  );
}

export default ButtonPage;
